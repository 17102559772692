<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-list subheader>
      <signed-in-user-list-tile></signed-in-user-list-tile>
      <v-divider></v-divider>
      <v-list-item
        v-for="item in $store.getters.moreNavItems"
        :key="item.text"
        :disabled="item.disabled"
        :to="item.disabled ? '' : item.to"
      >
        <v-list-item-avatar
          :tile="typeof item.badgeCnt === 'number' && item.badgeCnt > 0"
        >
          <v-badge v-if="item.badgeCnt && item.badgeCnt > 0" overlap>
            <template #badge>
              <span>{{ item.badgeCnt }}</span>
            </template>
            <v-icon>{{ item.icon }}</v-icon>
          </v-badge>
          <v-icon v-else>{{ item.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          {{ item.text }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import SignedInUserListTile from "@/components/SignedInUserListTile";

export default {
  data: () => ({}),
  components: {
    SignedInUserListTile,
  },
  computed: {
    ...mapState({
      tvMessageUnreadCount: ({ tvmessage }) => tvmessage.unreadCount,
      tvMessages: ({ tvmessage }) => tvmessage.items,
      cartQuantity: ({ shop }) => shop.cartQuantity,
    }),
  },
};
</script>
